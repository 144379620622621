<template>
  <div id="header" class="header">
    <div class="header-form" v-if="(isDevice == 'mobile' || isDevice == 'tablet') && searchMobileEnabled">
       <div class="form__icon form__icon_left sft-i sft-i-search"></div>
       <div class="header-input">
          <input 
            type="text"
            ref="input-search"
            placeholder="Поиск на сайте"
            v-model="textSearch"
            @keypress.enter="search()"
          />
        </div>
        <div class="form__icon form__icon_right" @click="searchMobileEnabled = false">
          <SvgClose viewBox="0 0 20 20" />
        </div>
    </div>
    <div class="header-main">
      <div class="header-main-container">
        <div class="header-main-container-logo">
          <a href="/">
            <SoftLogo />
          </a>
        </div>
        <div class="header-main-container-info">
          <a class="header-main-container-info-link"
            :class="{'header-main-container-info-active': currentPath==='/about'}"
            href="/about">О бренде</a>
          <a class="header-main-container-info-link" 
            :class="{'header-main-container-info-active': currentPath==='/store'}"
            href="/store">Где купить</a>
          <a class="header-main-container-info-link" 
            :class="{'header-main-container-info-active': currentPath==='/partners'}"
            href="/partners">Партнерам</a>
        </div>
        <div class="header-main-container-search">
          <div class="header-main-container-search-input">
            <input
              class="header-main-container-search-input-elem"
              :class="{ 'show-search': showSearch }"
              type="text"
              ref="input-search"
              placeholder="Поиск на сайте"
              v-model="textSearch"
              @focus="sfocus = true"
              @blur="sfocus = false"
              @keypress.enter="search()"
            >
            <button 
              class="header-main-container-search-input-button"
              @click="toggleSearch"
            >
              <img src="@/assets/img/search.png" alt="search">
            </button>
          </div>

          <div class="header-main-container-search-japan">
            <div class="header-main-container-search-japan-official">
              <a href="https://www.soft99.co.jp/en/" target="_blank">
                <Japan viewBox="0 0 48 30" />
                <span>японский сайт</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header-items">
      <div class="header-items-container">
        <a
          class="header-items-container-item"
          :class="{'header-items-container-active': isActiveRoute(item.href), 'header-items-container-border':index!== menuUpdate.length-1}"
          :href="item.href"
          v-for="(item, index) in menuUpdate"
          :key="index">
          {{ item.title }}
        </a>
      </div>
    </div>
  </div>
</template>


<script>
import MenuJson from '@/assets/data/menu.json';
import MenuUpdateJson from '@/assets/data/menu-update.json'
import SoftLogo from '@/assets/img/svg/soft99.svg?inline';
import Japan from '@/assets/img/svg/japan.svg?inline';
import SvgClose from '@/assets/img/svg/close.svg?inline';

export default {
  name: 'header-component',
  components: {
    Japan,
    SoftLogo,
    SvgClose
  },
  props: {
    isDevice: null,
  },
  data() {
    return {
      menu: MenuJson,
      menuUpdate: MenuUpdateJson,
      sfocus: false,
      textSearch: '',
      searchMobileEnabled: false,
      showSearch: false,
    }
  },
  methods: {
    search(callInput = false) {
      this.searchMobileEnabled = callInput;
      const delay = callInput ? 0 : 100;
      setTimeout(() => {
        let input = this.$refs['input-search'];

        if (input) {
    
          if (Array.isArray(input)) {
            input = input[0]
          }  

          input.focus();
          if (input.value.length > 2 && this.$route.query.text !== input.value) {
            this.$router.push({name: 'search', query: {text: input.value}})
            if (this.$route.name === 'search') this.$router.go()
          }
        }
      }, delay);
    },
    toggleSearch() {
      this.showSearch = !this.showSearch;
      if (this.showSearch) {
        this.$nextTick(() => {
          this.$refs['input-search'].focus();
        });
      }
    },
    isActiveRoute(href) {
      return this.currentPath === href;
    },
  },
  computed: {
    currentPath() {
      return this.$route.path;
    }
  },
}
</script>


<style scoped>
.border-beige {
  border-right: 1px solod white;
}

.header-main-container-search-input-elem {
  width: 0;
  padding: 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.header-main-container-search-input-elem.show-search {
  width: 89%;
  padding: 5px;
  opacity: 1;
}
</style>